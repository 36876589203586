import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { head, get } from 'lodash/fp'
import { Text, Box } from 'grommet'

import orderSetProductSku from '../store/actions/orderSetProductSku'

import ContentContainer from './ContentContainer'
import Heading from './Heading'

const ProductOptionsLetters = ({ orderSetProductSku, data }) => {
  useEffect(() => {
    orderSetProductSku({
      product: 'Letter',
      sku: get('id', head(data)),
    })
  })

  return (
    <ContentContainer justify="center">
      <Box pad="small">
        <Text size="small">Sizes</Text>

        <Box pad={{ vertical: 'medium' }}>
          <Heading lower level={4} margin={{ vertical: 'none' }}>
            Letter - 8.5 x 11
          </Heading>
          <Text size="small">8.5 Inches By 11 Inches</Text>
        </Box>
        <Box pad={{ vertical: 'medium' }}>
          <Heading lower level={4} margin={{ vertical: 'none' }}>
            Envelope - 4.125 X 9.5
          </Heading>
          <Text size="small">4.125 Inches By 9.5 Inches</Text>
        </Box>
      </Box>
    </ContentContainer>
  )
}

ProductOptionsLetters.propTypes = {
  orderSetProductSku: PropTypes.func,
  data: PropTypes.array,
}

export default connect(
  null,
  { orderSetProductSku }
)(ProductOptionsLetters)
