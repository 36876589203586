import React from 'react'
import { Link } from 'gatsby'
import { Box } from 'grommet'

import Button from '../../components/Button'
import ResponsiveHidden from '../../components/ResponsiveHidden'
import ContentContainer from '../../components/ContentContainer'
import ColumnStack from '../../components/ColumnStack'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import VisualLetters from '../../components/VisualLetters'
import DataBaseLetters from '../../components/DataBaseLetters'
import ProductOptionsLetters from '../../components/ProductOptionsLetters'
import { ChevronDown } from '../../components/Icons'

const LettersIndex = () => (
  <Layout productNav="letters">
    <SEO title="Letters" />
    <ColumnStack pad={{ vertical: 'medium' }} fill gap="large">
      <Box flex>
        <ResponsiveHidden>
          <ContentContainer side="left" justify="center">
            <VisualLetters justify="center" />
          </ContentContainer>
        </ResponsiveHidden>
        <Link to="/">
          <Button label="Previous" />
        </Link>
      </Box>
      <Box flex>
        <DataBaseLetters justify="center" Component={ProductOptionsLetters} />
        <Box align="end" margin={{ top: 'medium' }}>
          <Link to="/letters/design">
            <Button
              alignSelf="end"
              pad="large"
              primary
              label="Next"
              reverse
              icon={<ChevronDown size="small" />}
            />
          </Link>
        </Box>
      </Box>
    </ColumnStack>
  </Layout>
)

export default LettersIndex
